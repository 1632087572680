.App {
  text-align: center;  
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  color: white;  
  text-shadow: 0 2px 5px BLACK;  
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;  
  height: 100%;
  z-index: 1;  
  padding: 0;
  margin: 0;
  background: rgb(0 0 0 / 27%);
}

.App-header h1{
  margin: 0;
  padding: 0;
  font-size: 40px;
  margin-bottom: 100px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App .container {
  padding: 0;
  background: #293640;
  flex: 1;
}

.slick-track
{
    display: flex !important;
}

.slick-slide
{
    height: inherit !important;
}

.slick-slide img {
  margin: auto;  
  display: block;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.slick-prev, .slick-next{
  z-index: 10;    
}

.slick-prev{left: 50px;}
.slick-next{right: 50px;}
  